<template>
  <v-container
    fluid
    class="fill-height"
    grid-list-sm
  >
    <v-row no-gutters
      align="center"
      justify="center"
      grid-list-md
    >
      <v-col cols="12" xs="12" sm="10" md="5" class="py-0">
        <v-stepper v-model="stepElement" vertical>
          <v-stepper-step :complete="stepElement > 1" step="1">Дані для реєстрації (e-mail, пароль)</v-stepper-step>
          <v-stepper-content step="1">
            <v-card
              flat
              class="mb-3 px-3 pt-3 pb-0 border"
            >
              <v-form ref="formPassword" v-model="validFormPassword">
                <v-text-field
                  label="E-mail"
                  name="email"
                  prepend-icon="mdi-email-outline"
                  type="email"
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  ref="email_ref"
                  validate-on-blur
                  @blur="checkUserName"
                  @change="checkUserName"
                  :loading="loading"
                  outlined
                  :tabindex="stepElement === 1 ? '1' : ''"
                />
                <v-text-field
                  id="password"
                  label="Пароль"
                  name="password"
                  prepend-icon="mdi-lock-outline"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  :rules="[rules.required, rules.valueLength(password, 'Пароль', 6)]"
                  validate-on-blur
                  @click:append="showPassword = !showPassword"
                  outlined
                  :tabindex="stepElement === 1 ? '2' : ''"
                />
                <v-text-field
                  id="confirmPassword"
                  label="Пароль (ще раз)"
                  name="confirmPassword"
                  prepend-icon="mdi-lock-outline"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  v-model="confirmPassword"
                  :rules="[rules.required, rules.equal(confirmPassword, 'Паролі не співпадають', password)]"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                  outlined
                  :tabindex="stepElement === 1 ? '3' : ''"
                />
                <v-checkbox v-model="termsOfService" class="mt-0" color="success" required :rules="[rules.boolean]">
                  <template v-slot:label>
                    <div>
                      Даю згоду на обробку
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            href="/terms/personal_data"
                            @click.stop
                            target="_blank"
                            v-on="on"
                          >
                            персональних даних
                          </a>
                        </template>
                        Умови, положення та правила обробки персональних даних
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
              </v-form>
            </v-card>
            <v-btn
              color="primary"
              @click="stepElement = 2"
              :disabled="!validFormPassword"
            >
              Наступна
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="stepElement > 2" step="2">Детальна інформація про користувача</v-stepper-step>
          <v-stepper-content step="2">
            <v-card
              flat
              class="mb-3 px-3 pt-3 pb-0 border"
            >
              <v-form ref="formName" v-model="validFormName">
                <v-text-field
                  label="Прізвище"
                  name="lastName"
                  prepend-icon="mdi-account-outline"
                  type="text"
                  v-model="lastName"
                  :rules="[rules.required, rules.valueLength(lastName, 'Прізвище', 2)]"
                  validate-on-blur
                  outlined
                  :tabindex="stepElement === 2 ? '1' : ''"
                />
                <v-text-field
                  label="Ім’я"
                  name="firstName"
                  prepend-icon="mdi-account-outline"
                  type="text"
                  v-model="firstName"
                  :rules="[rules.required, rules.valueLength(firstName, 'Ім’я', 2)]"
                  validate-on-blur
                  outlined
                  :tabindex="stepElement === 2 ? '2' : ''"
                />
                <v-text-field
                  label="По батькові"
                  name="middleName"
                  prepend-icon="mdi-account-outline"
                  type="text"
                  v-model="middleName"
                  :rules="[rules.required, rules.valueLength(middleName, 'По батькові', 2)]"
                  validate-on-blur
                  outlined
                  :tabindex="stepElement === 2 ? '3' : ''"
                />
                <v-row class="py-0">
                  <v-col cols="6" md="6" class="py-0">
                    <v-select
                      :items="sexItems"
                      prepend-icon="mdi-gender-male-female"
                      v-model="sex"
                      :rules="[rules.required]"
                      label="Стать"
                      outlined
                      :tabindex="stepElement === 2 ? '4' : ''"
                    />
                  </v-col>
                  <v-col cols="6" md="6" class="py-0">
                    <v-menu
                      ref="birthDateMenu"
                      v-model="birthDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      :tabindex="stepElement === 2 ? '5' : ''"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          label="Дата народження"
                          name="birthDate"
                          prepend-icon="mdi-calendar"
                          type="text"
                          :value="birthDateFormatted"
                          :rules="[rules.required]"
                          readonly
                          v-on="on"
                          outlined
                        />
                      </template>
                      <v-date-picker
                        ref="birthDatePicker"
                        v-model="birthDate"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1930-01-01"
                        @change="saveBirthDate"
                      />
                    </v-menu>
                  </v-col>
                  <v-col md="12" xs="12">
                    <v-switch
                      v-model="reminder"
                      label="Нагадувати про необхідність оплати"
                      color="success"
                      class="ma-0 pa-0"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
            <v-btn text @click="stepElement = 1">
              Попередня
            </v-btn>
            <v-btn color="primary" class="ml-2" @click="stepElement = 3" :disabled="!validFormName">
              Наступна
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="stepElement > 3" step="3">Завершення реєстрації</v-stepper-step>
          <v-stepper-content step="3">
            <div v-if="addresses.length">
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>Перелік адрес</v-toolbar-title>
                <v-spacer/>
                <SearchDialog @getSecretIdFromSearch="getSecretIdFromSearch" :showIcon="false" :outlined="false"/>
              </v-toolbar>
              <v-card class="pa-2 border-x" tile>
                <v-list>
                  <v-list-item-group color="grey darken-3">
                    <v-list-item v-for="(address, index) in addresses" :key="index">
                      <v-list-item-icon>
                        <v-icon>mdi-domain</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="address.title"/>
                      </v-list-item-content>
                      <v-list-item-action>
                        <confirmDialog @confirmModal="confirmModal" :item="index" :divider="false" :showIcon="true" :icon="'mdi-delete-empty'" :headerColor="'error white--text mb-1'">
                          <template v-slot:buttonName>
                          </template>
                          <template v-slot:header>
                            Ви впевнені в вилучені адреси?
                            <v-divider/>
                          </template>
                          <template v-slot:content>Якщо ви на даному етапі вилучите адресу, вона не буде автоматично створена в вашому обліковому записі, відповідно - ви не зможете переглядати інформацію</template>
                        </confirmDialog>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </div>
            <div v-else class="mt-3">
              <v-alert border="left"
                       colored-border
                       type="info"
                       elevation="2">
                <v-row no-gutters  align="center" wrap>
                  <div>
                    <p class="subtitle-1 font-weight-light">Тут Ви можете додати перелік адрес для зручної оплати комунальних послуг, передачі показів, контролю стану заборгованості</p>
                  </div>
                  <v-spacer/>
                  <SearchDialog @getSecretIdFromSearch="getSecretIdFromSearch" :showIcon="false" :color="'info'" :text="false"/>
                </v-row>
              </v-alert>
            </div>
            <div class="mt-3">
              <v-btn text @click="stepElement = 2">
                Попередня
              </v-btn>
              <v-btn color="primary" class="ml-2" @click="getVerificationCode">
                Наступна
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-step :complete="stepElement === 4" step="4">Підтвердження електронної пошти</v-stepper-step>
          <v-stepper-content step="4">
            <v-card
              flat
              class="mb-3 pt-3 pb-5"
            >
              <v-card-text class="text-md-center text-xs-center">
                Введіть код підтвердження, який прийшов Вам на електронну пошту
              </v-card-text>

              <v-form ref="formVerification" v-model="validVerification">
                <v-container fluid class="pa-0 px-1">
                  <v-row no-gutters  justify="center" nowrap class="verificationInput">
                    <v-text-field
                      label=""
                      id="code1"
                      name="code1"
                      v-model="code1"
                      type="text"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      d-block
                      class="text-md-center"
                      @keyup="focus"
                      maxlength="1"
                    />
                    <v-text-field
                      label=""
                      id="code2"
                      name="code2"
                      v-model="code2"
                      type="text"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      d-block
                      @keyup="focus"
                      maxlength="1"
                    />
                    <v-text-field
                      label=""
                      id="code3"
                      name="code3"
                      v-model="code3"
                      type="text"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      d-block
                      @keyup="focus"
                      maxlength="1"
                    />
                    <v-text-field
                      label=""
                      id="code4"
                      name="code4"
                      v-model="code4"
                      type="text"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      d-block
                      @keyup="focus"
                      maxlength="1"
                    />
                    <v-text-field
                      label=""
                      id="code5"
                      name="code5"
                      v-model="code5"
                      type="text"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      d-block
                      @keyup="focus"
                      maxlength="1"
                    />
                    <v-text-field
                      label=""
                      id="code6"
                      name="code6"
                      v-model="code6"
                      type="text"
                      :rules="[rules.required]"
                      validate-on-blur
                      outlined
                      d-block
                      @keyup="focus"
                      maxlength="1"
                    />
                  </v-row>
                </v-container>
              </v-form>

              <v-card-text class="text-md-center text-xs-center">
                <a
                  href="#"
                  @click.stop="getVerificationCodeAgain"
                  :class="replayVerificationCode ? '' : 'disabled-href'"
                >
                  Надіслати код підтвердження ще раз?
                </a>
              </v-card-text>

              <v-card-text class="text-md-center text-xs-center" v-if="verificationError">
                <v-alert outlined type="error">
                  Введний код підтвердження не дійсний або не вірний, спробуйте отримати його ще раз
                </v-alert>
              </v-card-text>
            </v-card>
            <v-btn text @click="stepElement = 3">
              Попередня
            </v-btn>
<!--            <v-btn color="primary" class="ml-2" @click="onSubmit" :disabled="!validVerification">-->
<!--              Наступна-->
<!--            </v-btn>-->
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import AccountAPI from '@/api/account'
  import UserAPI from '@/api/user'
  import { getAddressTitle, VALIDATION_RULES } from "@/utils";
  import SearchDialog from '@/components/searchAccount/SearchDialog';
  import confirmDialog from '@/components/global/confirmDialog';
  import { map, assign } from 'lodash'
  import {AUTH_REQUEST} from '@/store/modules/authentication/mutation-types';

  export default {
    name: "Registration",
    data() {
      return {
        stepElement: 1,
        addresses: [],
        validFormPassword: true,
        validFormName: true,
        validVerification: true,
        loading: false,
        email: '',
        password: '',
        showPassword: false,
        confirmPassword: '',
        showConfirmPassword: false,
        termsOfService: false,
        firstName: '',
        lastName: '',
        middleName: '',
        birthDate: null,
        birthDateMenu: false,
        reminder: false,
        sex: null,
        sexItems: [
          {text: 'Чоловіча', value: 'male'},
          {text: 'Жіноча', value: 'female'},
        ],
        showDeleteDialog: false,
        code1: '',
        code2: '',
        code3: '',
        code4: '',
        code5: '',
        code6: '',
        replayVerificationCode: false,
        VerificationCodeSend: false,
        verificationError: false,
      }
    },
    computed: {
      rules() {
        return VALIDATION_RULES;
      },
      birthDateFormatted() {
        return this.birthDate ? moment(this.birthDate).format('DD.MM.YYYY') : '';
      },
      code() {
          return this.code1 + this.code2 + this.code3 + this.code4 + this.code5 + this.code6;
      }
    },
    methods: {
      async getAccount(schema, secret_id) {
          try {
              let address = {};
              const {data} = await AccountAPI.accountView(schema, secret_id);
              address.schema = schema;
              address.secret_id = secret_id;
              address.title = getAddressTitle(data.person);
              this.addresses.push(address);
          }
          catch (error) {
              this.$snackbar("Помилка додавання адреси", 'error')
          }
      },
      async registerNewUser() {
          try {
              let newUser = {};
              newUser.username = this.email.toLowerCase();
              newUser.password = this.password;
              newUser.last_name = this.lastName;
              newUser.first_name = this.firstName;
              newUser.middle_name = this.middleName;
              newUser.email = this.email.toLowerCase();
              newUser.birthday = this.birthDate;
              newUser.verification_code = this.code;
              newUser.sex = this.sex;
              newUser.addresses = map(this.addresses, );
              newUser.get_reminders = this.reminder;

              newUser.addresses = map(this.addresses, (item) => {
                  return assign({}, {
                      schema: item.schema,
                      secret_id: item.secret_id
                  })
              });

              const response = await UserAPI.registration(newUser);
              if (response.status === 200) {
                  this.$store.dispatch(AUTH_REQUEST, newUser)
                      .then(() => {
                          this.$router.push(this.$route.query.redirect || '/');
                      });
              }

          } catch (e) {
              this.$snackbar("Помилка реєстрації користувача", 'error')
          }
      },
      async sendVerificationCode() {
          try {
              await UserAPI.sendVerification({ email: this.email });
          } catch (e) {
              this.$snackbar("Код підтвердження не відправлений", 'success')
          }
      },
      async checkVerificationCode() {
          this.verificationError = false;
          try {
              const {data} = await UserAPI.checkVerification({ email: this.email, code: this.code });

              this.verificationError = !data;
          } catch (e) {
            this.verificationError = true;
          }
      },
      async checkUserName() {
          this.loading = true;
          try {
              let emailRef = this.$refs.email_ref;
              const {data} = await UserAPI.checkUserName({ username: this.email.toLowerCase() })
              this.loading = false;
              if (data) {
                  emailRef.errorBucket.push('Користувач з таким email вже зареєстрований');
              }

          } catch (e) {
              this.loading = false;
          }
      },
      onSubmit() {
        if (this.$refs.formName.validate() && this.$refs.formPassword.validate()) {
            this.registerNewUser();
        }
      },
      getVerificationCode() {
          if (!this.VerificationCodeSend) {
              this.sendVerificationCode();
              this.VerificationCodeSend = true;
          }
          this.stepElement = 4;
      },
      getVerificationCodeAgain() {
          this.replayVerificationCode = false;
          this.sendVerificationCode();
          this.replayVerificationCodeAvailable(20);
      },
      saveBirthDate (date) {
        this.$refs.birthDateMenu.save(date)
      },
      confirmModal(value) {
          if (value.question) {
             this.addresses.splice(value.item, 1);
          }
      },
      getSecretIdFromSearch(value) {
        if (!this.addresses.some(address => address.secret_id === value.secret_id)) {
          this.getAccount(value.schema, value.secret_id)
        }
      },
      focus(e) {
          // if (e.ctrlKey && e.keyCode===86) {
          //     return false;
          // }

          const currentElement = e.target.id;
          const currentId = parseInt(currentElement.slice(4));
          const nextId = currentId + 1;
          const prevId = currentId - 1;

          const nextElement = currentElement.slice(0, 4) + nextId;
          const prevElement = currentElement.slice(0, 4) + prevId;

          if (e.keyCode === 8 && currentId > 1) {
              document.getElementById(prevElement).focus();
          } else {
              if (currentId < 6) {
                  if (parseInt(e.target.value) || e.target.value === '0') {
                      document.getElementById(nextElement).focus();
                  }
              } else {
                this.validateVerificationCode();
              }
          }
      },
      validateVerificationCode() {
        if (this.$refs.formVerification.validate()) {
          this.checkVerificationCode().then(() => {
              if (!this.verificationError) {
                this.onSubmit();
              }
            }
          )
        }
      },
      replayVerificationCodeAvailable(seconds) {
          setTimeout(() => {
            this.replayVerificationCode = true;
          },seconds * 1000)
      }
    },
    components: {
      confirmDialog,
      SearchDialog
    },
    watch: {
      birthDateMenu (val) {
        val && setTimeout(() => (this.$refs.birthDatePicker.activePicker = 'YEAR'))
      },
    },
    mounted() {
      const schema = this.$route.query.schema;
      const secret_id = this.$route.query.secret_id;

      if (schema && secret_id) {
          this.getAccount(schema, secret_id);
      }
    }

  }
</script>

<style scoped lang="scss">
  /*.border {*/
  /*  border: .5px solid #4caf504f;*/
  /*}*/
  /*.border-x {*/
  /*  border-top-left-radius: 0 !important;*/
  /*  border-top-right-radius: 0 !important;*/
  /*  border-left: .5px solid #4caf504f;*/
  /*  border-right: .5px solid #4caf504f;*/
  /*}*/
  .verificationInput {
    &::v-deep .v-text-field.v-text-field--enclosed .v-input__control .v-input__slot .v-text-field__slot input {
      text-align: center;
    }
    &::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
      display: none !important;
    }
    &::v-deep .v-text-field.v-text-field--enclosed {
      max-width: 60px;
      max-height: 60px;
      margin-right: 4px;
    }
  }
  .disabled-href {
    pointer-events: none;
  }
</style>
